<script>
import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

/**
 * Login component
 */
export default {
  page: {
    title: "Sign Up",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      email: "",
      password: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      passViewState : "password",
      confirmPassViewState : "password",
      valueFirstName : "",
      valueLastName : "",
      valueConfirmPassword : "",
      termCondition : false,
      loading : false,
      landing : process.env.VUE_APP_LANDING
    };
  },
  validations: {
    valueFirstName: {
      required,
      minLength: minLength(2)
    },
    valueLastName: {
      required,
      minLength: minLength(2)
    },
    email: {
      required,
      email,
      isUnique (value) {
        if (value === '') return true
        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(email_regex.test(value))
          }, 350 + Math.random()*300)
        })
      }
    },
    password: {
      required,
      minLength: minLength(8)
    },
    valueConfirmPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  mounted() {
    // this.$ga.page('/signup')
    this.$ga.event('signup-page-v2', 'actionx', 'labelx', 1)
  },
  methods: {
    ...authMethods,
    viewPass(){
      this.passViewState = this.passViewState == "password" ? "text" : "password";
    },
    landingPage(){
      window.open(this.landing,"_self")
    },
    isNumber(evt){
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode >= 48 && charCode <= 57) && this.valuePhoneNumber.length <= 13) {
            return true
        } else {
            evt.preventDefault();
      }
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.$ga.event('send', 'register', 'action', 'label', 1)
      this.$ga.event('registerV2x', 'actionV2x', 'labelV2x', 1)
      this.loading = true;
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.register({
          email: this.email,
          password: this.password,
          firstname: this.valueFirstName,
          lastname : this.valueLastName,
          confirm_password : this.valueConfirmPassword
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            this.$router.push(
              this.$route.query.redirectFrom || { name: "Verify" }
            );
          })
          .catch(error => {
            this.tryingToLogIn = false;
            this.authError = error ? error : "";
            this.isAuthError = true;
            this.loading = false;
          })
      );
    }
  }
};
</script>

<template>
  <Layout>
    <div class="d-flex justify-content-center align-items-center registerPageV2">
      <div class="row">
        <div class="col-md-1 before-left-register"></div>
        <div class="col-md-5 left-register">
          <h1 class="title-signup-left">Access and collaborate on your work files securely.<br><span class="title-signup-left-blue">All in one place.</span></h1>
          <hr class="horizontal-line">
          <p class="comment-title">Here’s what they’re saying about us:</p>
          <div class="row">
            <div class="col-md-6">
              <b-card class="comment-sections">
                <b-card-text class="mb-3">
                  <p>Mohamad Rizal</p>
                  <p><span>Managing Director, RZ Cloud Solutions</span></p>
                </b-card-text>

                <b-card-text>
                  <p class="mb-1">“Jaybod is critical to help our team collaborate and has made it even easier to work together.”</p>
                </b-card-text>
                <br><br><br>

                <div class="row">
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                </div>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card class="comment-sections">
                <b-card-text class="mb-3">
                  <p>Helmi Abdul Mutalib</p>
                  <p><span>Managing Director,  Excell Media Studio</span></p>
                </b-card-text>

                <b-card-text>
                  <p class="mb-3">“Not having access to all our information would make it impossible to stay productive. But with Jaybod the information is all there in one place.  ”</p>
                </b-card-text>
                
                <div class="row">
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  <div class="col-md-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                </div>
              </b-card>
            </div>
          </div>
          <img src="@/assets/images/square-border-blue.png" class="backgrounds" alt="">
        </div>
        <div class="col-md-1 center-register"></div>
        <div class="col-md-4 justify-content-center ">
          <div class="right-register">
            <!-- <div class="px-4">
              <div class="card overflow-hidden card-no-shadow">
                <div class="card-body pt-4"> -->
                  <h1 class="mb-4 text-start h1-title">Get 5 GB storage for free. <br>Sign up today!</h1>
                  <b-alert :show="isAuthError" variant="danger">{{authError}}</b-alert>
                  <p class="default-text-style text-start">Already have an account? <router-link tag="a" to="/login" class="text-blue">Sign In</router-link></p>
                  <div class="row">
                    <b-form class="p-2" @submit.prevent="tryToLogIn">
                      <div class="row">
                          <div class="col-md-6">
                                <b-form-group id="input-group-1" class="" label-class="label-input" label-for="input-1">
                                    <b-form-input
                                      id="input-1"
                                      class="login-input-style"
                                      v-model.trim="$v.valueFirstName.$model"
                                      :class="{'is-invalid':$v.valueFirstName.$error, 'is-valid':!$v.valueFirstName.$invalid}"
                                      type="text"
                                      placeholder="First Name"
                                    ></b-form-input>
                                    <!-- <div class="valid-feedback">Your first name is valid</div> -->
                                    <div class="invalid-feedback">
                                      <span v-if="!$v.valueFirstName.required">First name is required.</span>
                                      <span v-if="!$v.valueFirstName.minLength">First name must have at least
                                        {{$v.valueFirstName.$params.minLength.min}}.</span>
                                    </div>
                                </b-form-group>
                          </div>
                          <div class="col-md-6">
                                <b-form-group id="input-group-2" class="" label-class="label-input" label-for="input-1">
                                    <b-form-input id="input-2"
                                      class="login-input-style"
                                      v-model.trim="$v.valueLastName.$model"
                                      :class="{'is-invalid':$v.valueLastName.$error, 'is-valid':!$v.valueLastName.$invalid}"
                                      type="text"
                                      placeholder="Last Name"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      <span v-if="!$v.valueLastName.required">Last name is required.</span>
                                      <span v-if="!$v.valueLastName.minLength">Last name must have at least
                                        {{$v.valueLastName.$params.minLength.min}}.</span>
                                    </div>
                                </b-form-group>
                          </div>
                      </div>
                      <!-- <b-form-group id="input-group-1" class="py-2" :invalid-feedback="invalidPhoneNumber" :state="statePhoneNumber"   label-class="label-input" label="Phone Number" label-for="input-1">
                        <b-input-group>
                            <template #prepend>
                                <b-dropdown class="dropdown-input">
                                    <template #button-content>
                                        <img src="@/assets/images/country/usa.jpeg" height="16" width="26" alt  />
                                        <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                                    </template>
                                    <b-dropdown-item>Action A</b-dropdown-item>
                                    <b-dropdown-item>Action B</b-dropdown-item>
                                </b-dropdown>
                            </template>                
                            <b-form-input id="input-1" v-on:keypress="isNumber(event)" class="login-input-style" v-model="valuePhoneNumber" type="text" placeholder="Phone Number"></b-form-input>
                        </b-input-group>
                      </b-form-group> -->

                      <b-form-group id="input-group-3" class="" label-class="label-input" label-for="input-1">
                        <b-form-input
                          id="input-3"
                          class="login-input-style"
                          v-model.trim="$v.email.$model"
                          :class="{'is-invalid':$v.email.$error, 'is-valid':!$v.email.$invalid}"
                          type="email"
                          placeholder="Email"
                        ></b-form-input>
                        <!-- <div class="valid-feedback">Your email is valid</div> -->
                        <div class="invalid-feedback">
                          <span v-if="!$v.email.required">Email is required.</span>
                          <span v-if="!$v.email.email">Invalid email format</span>
                          <!-- <span v-if="!$v.email.isUnique">This email is already registered.</span> -->
                        </div>
                      </b-form-group>

                      <b-form-group id="input-group-4" class="py-2 mt-2" label-class="label-input"  label-for="input-2">
                        
                        <b-input-group>
                          <b-form-input
                            id="input-4"
                            v-model.trim="$v.password.$model"
                            :class="{'is-invalid':$v.password.$error, 'is-valid':!$v.password.$invalid}"
                            :type="passViewState"
                            class="login-input-style border-right-none"
                            placeholder="Password"
                          ></b-form-input>
                          
                          <b-input-group-append>
                              <b-button   @click="()=>{passViewState = (passViewState == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                                <i :class="(passViewState != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                              </b-button>
                          </b-input-group-append>
                          <div class="invalid-feedback">
                            <span v-if="!$v.password.required">Password is required.</span>
                            <span v-if="!$v.password.minLength">Minimum character
                              {{$v.password.$params.minLength.min}}.</span>
                          </div>
                        </b-input-group>
                      </b-form-group>

                      <b-form-group id="input-group-5" class="py-2 mt-2" label-class="label-input"  label-for="input-2">
                        
                        <b-input-group>
                          <b-form-input
                            id="input-5"
                            v-model.trim="$v.valueConfirmPassword.$model"
                            :class="{'is-invalid':$v.valueConfirmPassword.$error, 'is-valid': (password != '') ?
                            !$v.valueConfirmPassword.$invalid : '' }"
                            :type="confirmPassViewState"
                            class="login-input-style border-right-none"
                            placeholder="Confirm Password"
                          ></b-form-input>
                          
                          <b-input-group-append>
                              <b-button   @click="()=>{confirmPassViewState = (confirmPassViewState == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                                <i :class="(confirmPassViewState != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                              </b-button>
                          </b-input-group-append>
                          <div class="invalid-feedback">
                            <span v-if="!$v.valueConfirmPassword.sameAsPassword">Confirm password must be identical.</span>
                          </div>
                        </b-input-group>
                      </b-form-group>


                      <!-- <b-form-group id="input-group-5"  :invalid-feedback="invalidConfirmPassword" :state="stateConfirmPassword"  class="" label-class="label-input float-left"  label-for="input-2">
                        <b-input-group>
                            <b-form-input
                                id="input-5"
                                v-model="valueConfirmPassword"
                                :type="passViewState"
                                class="login-input-style  border-right-none"
                                placeholder="Confirm Password"
                                >
                            </b-form-input>
                            <b-input-group-append>
                            <b-button   @click="viewPass" class="btn-on-input"><i :class="(passViewState != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                      </b-form-group> -->
                        <br>
                        <b-form-checkbox
                        id="checkbox-1"
                        v-model="termCondition"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted"
                        :disabled="$v.$invalid"
                        >
                        <p class="default-text-style">I have read and agree <span><a href="https://jaybod.com/terms-service" class="text-blue">Terms and Services, Privacy Policy, End User Agreement</a></span></p>
                        </b-form-checkbox>
                        <br>
                      <div class="d-flex justify-content-end align-items-center">
                          <button type="submit" :disabled="termCondition == 'not_accepted' ? termCondition : !termCondition" :class="termCondition == 'accepted' ? 'active btn base-btn' : 'btn base-btn'">
                              <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>                     
                              <span class="default-text-style"><span :class="termCondition == 'accepted' ? 'text-white' : ''">Sign Up</span></span>
                          </button>
                      </div>
                    </b-form>

                  </div>
                <!-- </div> -->
                <!-- end card-body -->
              <!-- </div> -->
              <!-- end card -->
              <!-- end row -->
            <!-- </div> -->
            <!-- end col -->
          </div>
        </div>
        <div class="col-md-1 after-right-register"></div>
        <div class="container-mobile display-mobile">
          <div class="left-resp-register">
            <!-- <div class="px-4">
              <div class="card overflow-hidden card-no-shadow">
                <div class="card-body pt-4"> -->
                  <h1 class="mb-4 text-start h1-title">Get 5 GB storage for free. <br>Sign up today!</h1>
                  <b-alert :show="isAuthError" variant="danger">{{authError}}</b-alert>
                  <p class="default-text-style text-start">Already have an account? <router-link tag="a" to="/login" class="text-blue">Sign In</router-link></p>
                  <div class="row">
                    <b-form class="p-2" @submit.prevent="tryToLogIn">
                      <div class="row">
                          <div class="col-md-6">
                                <b-form-group id="input-group-1" class="" label-class="label-input" label-for="input-1">
                                    <b-form-input
                                      id="input-1"
                                      class="login-input-style"
                                      v-model.trim="$v.valueFirstName.$model"
                                      :class="{'is-invalid':$v.valueFirstName.$error, 'is-valid':!$v.valueFirstName.$invalid}"
                                      type="text"
                                      placeholder="First Name"
                                    ></b-form-input>
                                    <!-- <div class="valid-feedback">Your first name is valid</div> -->
                                    <div class="invalid-feedback">
                                      <span v-if="!$v.valueFirstName.required">First name is required.</span>
                                      <span v-if="!$v.valueFirstName.minLength">First name must have at least
                                        {{$v.valueFirstName.$params.minLength.min}}.</span>
                                    </div>
                                </b-form-group>
                          </div>
                          <div class="col-md-6">
                                <b-form-group id="input-group-2" class="" label-class="label-input" label-for="input-1">
                                    <b-form-input id="input-2"
                                      class="login-input-style"
                                      v-model.trim="$v.valueLastName.$model"
                                      :class="{'is-invalid':$v.valueLastName.$error, 'is-valid':!$v.valueLastName.$invalid}"
                                      type="text"
                                      placeholder="Last Name"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      <span v-if="!$v.valueLastName.required">Last name is required.</span>
                                      <span v-if="!$v.valueLastName.minLength">Last name must have at least
                                        {{$v.valueLastName.$params.minLength.min}}.</span>
                                    </div>
                                </b-form-group>
                          </div>
                      </div>
                      <!-- <b-form-group id="input-group-1" class="py-2" :invalid-feedback="invalidPhoneNumber" :state="statePhoneNumber"   label-class="label-input" label="Phone Number" label-for="input-1">
                        <b-input-group>
                            <template #prepend>
                                <b-dropdown class="dropdown-input">
                                    <template #button-content>
                                        <img src="@/assets/images/country/usa.jpeg" height="16" width="26" alt  />
                                        <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                                    </template>
                                    <b-dropdown-item>Action A</b-dropdown-item>
                                    <b-dropdown-item>Action B</b-dropdown-item>
                                </b-dropdown>
                            </template>                
                            <b-form-input id="input-1" v-on:keypress="isNumber(event)" class="login-input-style" v-model="valuePhoneNumber" type="text" placeholder="Phone Number"></b-form-input>
                        </b-input-group>
                      </b-form-group> -->

                      <b-form-group id="input-group-3" class="" label-class="label-input" label-for="input-1">
                        <b-form-input
                          id="input-3"
                          class="login-input-style"
                          v-model.trim="$v.email.$model"
                          :class="{'is-invalid':$v.email.$error, 'is-valid':!$v.email.$invalid}"
                          type="email"
                          placeholder="Email Address"
                        ></b-form-input>
                        <!-- <div class="valid-feedback">Your email is valid</div> -->
                        <div class="invalid-feedback">
                          <span v-if="!$v.email.required">Email is required.</span>
                          <span v-if="!$v.email.email">Invalid email format</span>
                          <!-- <span v-if="!$v.email.isUnique">This email is already registered.</span> -->
                        </div>
                      </b-form-group>

                      <b-form-group id="input-group-4" class="py-2 mt-2" label-class="label-input"  label-for="input-2">
                        
                        <b-input-group>
                          <b-form-input
                            id="input-4"
                            v-model.trim="$v.password.$model"
                            :class="{'is-invalid':$v.password.$error, 'is-valid':!$v.password.$invalid}"
                            :type="passViewState"
                            class="login-input-style border-right-none"
                            placeholder="Password"
                          ></b-form-input>
                          
                          <b-input-group-append>
                              <b-button   @click="()=>{passViewState = (passViewState == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                                <i :class="(passViewState != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                              </b-button>
                          </b-input-group-append>
                          <div class="invalid-feedback">
                            <span v-if="!$v.password.required">Password is required.</span>
                            <span v-if="!$v.password.minLength">Minimum character
                              {{$v.password.$params.minLength.min}}.</span>
                          </div>
                        </b-input-group>
                      </b-form-group>

                      <b-form-group id="input-group-5" class="py-2 mt-2" label-class="label-input"  label-for="input-2">
                        
                        <b-input-group>
                          <b-form-input
                            id="input-5"
                            v-model.trim="$v.valueConfirmPassword.$model"
                            :class="{'is-invalid':$v.valueConfirmPassword.$error, 'is-valid': (password != '') ?
                            !$v.valueConfirmPassword.$invalid : '' }"
                            :type="confirmPassViewState"
                            class="login-input-style border-right-none"
                            placeholder="Confirm Password"
                          ></b-form-input>
                          
                          <b-input-group-append>
                              <b-button   @click="()=>{confirmPassViewState = (confirmPassViewState == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                                <i :class="(confirmPassViewState != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                              </b-button>
                          </b-input-group-append>
                          <div class="invalid-feedback">
                            <span v-if="!$v.valueConfirmPassword.sameAsPassword">Confirm password must be identical.</span>
                          </div>
                        </b-input-group>
                      </b-form-group>


                      <!-- <b-form-group id="input-group-5"  :invalid-feedback="invalidConfirmPassword" :state="stateConfirmPassword"  class="" label-class="label-input float-left"  label-for="input-2">
                        <b-input-group>
                            <b-form-input
                                id="input-5"
                                v-model="valueConfirmPassword"
                                :type="passViewState"
                                class="login-input-style  border-right-none"
                                placeholder="Confirm Password"
                                >
                            </b-form-input>
                            <b-input-group-append>
                            <b-button   @click="viewPass" class="btn-on-input"><i :class="(passViewState != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                      </b-form-group> -->
                        <br>
                        <b-form-checkbox
                        id="checkbox-1"
                        v-model="termCondition"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted"
                        >
                        <p class="default-text-style">I have read and agree <span><a href="https://jaybod.com/terms-service" class="text-blue">Terms and Services, Privacy Policy, End User Agreement</a></span></p>
                        </b-form-checkbox>
                        <br>
                      <div class="d-flex justify-content-end align-items-center">
                          <button type="submit" :disabled="termCondition == 'not_accepted' ? termCondition : !termCondition" :class="termCondition == 'accepted' ? 'active btn base-btn' : 'btn base-btn'">
                              <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>                     
                              <span class="default-text-style"><span :class="termCondition == 'accepted' ? 'text-white' : ''">Sign Up</span></span>
                          </button>
                      </div>
                    </b-form>

                  </div>
                <!-- </div> -->
                <!-- end card-body -->
              <!-- </div> -->
              <!-- end card -->
              <!-- end row -->
            <!-- </div> -->
            <!-- end col -->
          </div>
          <div class="right-resp-register">
            <h1 class="title-signup-left-resp">Access and collaborate on your work files securely.<br><span class="title-signup-resp-left-blue">All in one place.</span></h1>
            <hr class="horizontal-line">
            <p class="comment-title">Here’s what they’re saying about us:</p>
            <div class="row">
              <div class="col-md-6">
                <b-card class="comment-sections">
                  <b-card-text class="mb-3">
                    <p>Mohamad Rizal</p>
                    <p><span>Managing Director, RZ Cloud Solutions</span></p>
                  </b-card-text>

                  <b-card-text>
                    <p class="mb-1">“Jaybod is critical to help our team collaborate and has made it even easier to work together.”</p>
                  </b-card-text>
                  <br><br><br>

                  <div class="row">
                    <div class="col-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card class="comment-sections">
                  <b-card-text class="mb-3">
                    <p>Helmi Abdul Mutalib</p>
                    <p><span>Managing Director,  Excell Media Studio</span></p>
                  </b-card-text>

                  <b-card-text>
                    <p class="mb-3">“Not having access to all our information would make it impossible to stay productive. But with Jaybod the information is all there in one place.  ”</p>
                  </b-card-text>
                  <br>
                  
                  <div class="row">
                    <div class="col-1 col-sm-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1 col-sm-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1 col-sm-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1 col-sm-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                    <div class="col-1 col-sm-1"><img src="@/assets/images/icon/star-blue.png" alt=""></div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" scoped>
.backgrounds{
  position: absolute;
  margin-left: -158px;
  margin-top: -261px;
  z-index: -99;
}
.left-register h1{
  font-size: 40px !important;
  color: #262A2C;
  line-height: 56px;
  font-weight: 600;
  letter-spacing: -2.2px;
}
.left-register h1 span{
  font-size: 40px !important;
  color: #00AAFF !important;
}
.horizontal-line{
  border: none;
  height: 2px;
  background: #00AAFF;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0.3px;
  width: 20%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 0px;
}
.comment-title{
  font-size: 16px;
  line-height: 24px;
  font-weight:400;
  margin-bottom: 25px;
}
.comment-sections{
  background-color: #E9F5FB;
  border-radius: 10px;
}
.comment-sections p{
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #262A2C;
}
.comment-sections p span{
  color: #919394;
  font-size: 12px;
}
.footer-card{
  position: absolute;
  bottom: 3px;
}
.title-signup-left-resp{
  font-size: 32px !important;
  color: #262A2C;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: -2.2px;
  margin-top: 80px;
}
.title-signup-resp-left-blue{
  color: #00AAFF;
}
.text-blue{
  color: #00AAFF !important;
}
.display-mobile{
  display: none;
}
@media (max-width: 787px) {
  .display-mobile{
    display: block;
  }
}
</style>
